
<template lang="pug">
//- user-select: none は safari のバグ対応
q-page.aq-page(
  v-bind="attrs"
  v-on="$listeners"
)
  .full-height.column.no-wrap
    .relative-position
      aq-toolbar.aq-page__header
        slot(name="header")
          slot(name="header-title-before")
            aq-menu-btn

          slot(name="header-title" :title="title_" :titleAfter="titleAfter_")
            q-toolbar-title {{title_}}{{titleAfter_}}

          slot(
            v-if="loadingStatus == null"
            name="header-title-after"
          )

      .aq-page__header-shadow.absolute-full.overflow-hidden.no-pointer-events(v-if="elevated")


    ASuspense.aq-page__content(
      v-if="loading != null"
      :loading="loading"
      @loaded="loaded"
    )
      slot

    .aq-page__content(v-else)
      slot

    .relative-position(
      v-if="$slots['footer']"
      elevated
    )
      .aq-page__footer-shadow.absolute-full.overflow-hidden.no-pointer-events(v-if="elevated")

      aq-toolbar.aq-page__footer
        slot(name="footer")
</template>

<script>
import {routes} from '@/router'

import ASuspense from '@/aax/components/ASuspense.vue'
import AqToolbar from '@/aax/components/AqToolbar.vue'
import AqMenuBtn from './AqMenuBtn.vue'

export default {
  components: {ASuspense, AqMenuBtn, AqToolbar},

  props: {
    title:      String,
    titleAfter: String,
    elevated:   Boolean,
    loading:    Function,
  },

  data() {
    return {
      loadingStatus: this.loading != null ? true : null,
    }
  },

  computed: {
    attrs() {
      return {
        styleFn: offset => ({height: offset ? 'calc(100vh - ' + offset + 'px)' : '100vh'}),
        ...this.$attrs,
      }
    },

    title_() {
      if(this.title != null) {
        return this.title
      }

      const title = this.$route.meta?.title
      if(title != null) {
        return title
      }

      const parent = this.$route.meta?.parent
      if(parent != null) {
        const title = routes.find(r => r.name === parent)?.meta?.title
        if(title != null) {
          return title
        }
      }

      return process.env.VUE_APP_TITLE
    },

    titleAfter_() {
      return this.titleAfter ? ' / ' + this.titleAfter : ''
    },
  },

  methods: {
    loaded(result) {
      this.loadingStatus = null
      this.$emit('loaded', result)
    },
  },
}
</script>

<style lang="sass">
.aq-page
  user-select: none

.aq-page__header,
.aq-page__header-shadow,
.aq-page__footer,
.aq-page__footer-shadow
  z-index: 2000

.aq-page__header-shadow::after,
.aq-page__footer-shadow::after
  bottom: 0
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2), 0 0 5px rgba(0, 0, 0, .24)
  content: ""
  left: 0
  position: absolute
  right: 0
  top: 0

.aq-page__header-shadow
  bottom: -10px

  &::after
    bottom: 10px

.aq-page__footer-shadow
  top: -10px

  &::after
    top: 10px

.aq-page__content
  flex: 1 1 0
  overflow: hidden
</style>
